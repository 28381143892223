



<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <b-row class="mt-5">
          <b-col>
            <b-alert :show="!!message" variant="danger">{{ message }}</b-alert>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(onRegister)">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Username:" label-for="input-username">
                      <validation-provider
                        name="Username"
                        rules="required|email"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-username"
                          v-model="user.username"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter username"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Name:" label-for="input-name">
                      <validation-provider
                        name="Name"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="user.name"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter name"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Entity Id:" label-for="input-entityId">
                      <validation-provider
                        name="EntityId"
                        rules="required|integer"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-entityId"
                          v-model="user.entityId"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter entity id"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Position Type:" label-for="select-positionType">
                      <validation-provider
                        name="Position"
                        rules="required"
                        v-slot="validationContext"
                      >
                      <b-form-select :state="getValidationState(validationContext)"  id="select-positionType" v-model="user.positionType" :options="options"></b-form-select>                        
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Password:" label-for="input-password">
                      <validation-provider
                        name="Password"
                        rules="required|confirmed:confirmation"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-password"
                          type="password"
                          v-model="user.password"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>                    
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Confirm Password:"
                      label-for="input-password"
                    >
                      <validation-provider
                        name="Confirm Password"
                        rules="required|confirmed:confirmation"
                        v-slot="validationContext"
                        vid="confirmation"
                      >
                        <b-form-input
                          id="confirm-password"
                          type="password"
                          v-model="user.confirmation"
                          :state="getValidationState(validationContext)"
                          placeholder="Confirm password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>                    
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-checkbox
                        id="check-petition"
                        v-model="user.isPetition"
                        name="check-petition"
                      >
                        Is Petition Candidate?
                      </b-form-checkbox>
                    </b-form-group>
                    <b-button type="submit" variant="primary" :disabled="loading">Register</b-button>                    
                  </b-col>
                </b-row>                                                
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>      
      </b-container>
    </main>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      user: {
        username: "",
        password: "",
        confirmation: "",
        isPetition: false,
        entityId:"",
        positionType:"",
        name:""
      },
      loading: false,
      message: "",
      options: [
          { value: null, text: 'Please select an option' },
          { value: '1', text: 'Officer' },
          { value: '2', text: 'Board' },          
        ]
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onRegister() {
      this.loading = true;
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/register", this.user).then(
          (data) => {
            this.$router.push(`/admin/${data.userId}/manage-users`);
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    },
  },
};
</script>